import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "@components/layout";
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import Animation modules
import { motion } from "framer-motion"

// Import SVG
import hatena_icon from '@images/hatena_icon.svg'; // ？マークの代わりのダミー
import partner_img01 from '@images/partner_img01.svg'; // 
import partner_icon1 from '@images/partner_icon1.svg'; // 
import partner_icon2 from '@images/partner_icon2.svg'; // 
import partner_icon4 from '@images/partner_icon4.svg'; // 
import partner_icon6 from '@images/partner_icon6.svg'; // 
import partner_icon8 from '@images/partner_icon8.svg'; // 
import partner_icon9 from '@images/partner_icon9.svg'; // 
import about_icon1 from '@images/about_icon1.svg'; // 
import about_icon2 from '@images/about_icon2.svg'; // 
import about_icon3 from '@images/about_icon3.svg'; // 
import about_icon4 from '@images/about_icon4.webp'; // 
import about_icon5 from '@images/about_icon5.svg'; // 
import about_icon6 from '@images/about_icon6.svg'; // 
import about_icon7 from '@images/about_icon7.svg'; // 
import about_icon8 from '@images/about_icon8.svg'; // 

import about_img01 from '@images/about_img01.svg'; // 
import about_img02 from '@images/about_img02.svg'; // 

import about_gno1 from '@images/g_no1.svg'; // 
import about_gno2 from '@images/g_no2.svg'; // 
import about_yno1 from '@images/y_no1.svg'; // 
import about_yno2 from '@images/y_no2.svg'; // 
import about_yno3 from '@images/y_no3.svg'; // 
import about_yno4 from '@images/y_no4.svg'; // 






import topphone from '@images/phone-image.webp';
import topimage01 from '@images/top-image01_2.webp';
import abouttokuchou01 from '@images/about_tokuchou01.webp';
import aboutImg01 from '@images/about_img01.webp';
import aboutImg01_sp from '@images/about_img01_sp.webp';

// 関数呼び出し
import { FixedFoot } from "@components/footer.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定


const PropAbout = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
 
  // Seo情報設定
  const pathName = 'beginner';
  const seoTag = SeoContents({ Path:pathName });

  let setArray;
  let pushArray = [];
  const pickupPath = "/";
  const LinkBeginner = "/beginner/";
  const LinkAbout = "/about/";
  
  
  

  return (
  <>
    <Layout>
      {seoTag}
    <div className="bg-[#F6F6F6] md:pb-[70px] pb-2">
        <div className="px-5 max-w-xl mx-auto md:max-w-5xl relative md:h-[300px] xl:overflow-visible overflow-hidden">
          <div className="pb-10 pt-0 md:pt-10  max-w-[800px] md:absolute md:left-5 md:top-[0%]">
            <h2 className="md:text-5xl text-3xl font-black mb-10 text-left pt-5">パートナープログラム</h2>
            <h3 className="md:text-3xl text-2xl font-black mb-5 text-left  text-[#1F55A6]">日本発の認定トレーダーになる仲間を探そう</h3>
              <p className="md:text-base text-base  font-black  md:max-w-[480px] xl:max-w-[600px]">日本におけるプロップトレーダーの数はまだまだ未知数です。<br></br>
              Just tradeと一緒になって認定トレーダーを増やしていくためのビジネスパートナーを募集しております。
              </p>
          </div>
          <div className="max-w-[650px] md:absolute md:right-[-23%] md:top-10">
               <motion.div className=" mx-auto text-center md:block md:mt-10 xl:mt-[0px] md:ml-[200px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
                 <img className="mb-5 mx-auto h-auto" alt="" src={topimage01}/>
               </motion.div>
          </div>
        </div>

      </div>
              {/* サブメニュー */}

       {/* 特徴 */}
      <div className="max-w-xl mx-auto md:max-w-5xl mt-[70px] px-5">
        <div className="md:flex mb-10">
          <div className="text-center md:px-[100px] px-5 md:mt-10 mb-5">
            <h4 className="text-xs mb-2  font-semibold">あなたの紹介したトレーダーがチャレンジ費用を購入すると</h4>
             <p className="text-5xl mb-2">最大<span className="text-[#DF5F6A]">00,000円</span></p>
            <p className="">の報酬が支払われます</p>
          </div>
          <div>
           <p><img src={partner_img01} alt=""></img></p>
          </div>
        </div>
      </div>



  <div className="bg-[#F6F6F6] pb-[50px] pt-[40px]">
    <div className="max-w-xl mx-auto md:max-w-5xl  px-5 ">
      {/* 特徴詳細1*/}
       <div className="mb-[70px]">
         <div className="mb-5 pt-10">
           <h4 className="border-l-2 border-[#1F55A6] text-lg mb-5  pl-3 font-semibold">パートナープログラムの特徴</h4>
           <p>海外FXのアフィリエイトプログラムと違い、トレードにおける自己資金は一切かかりませんので<br></br>
              リスクなしで紹介しやすいのが特徴です。
           </p>
         </div>
       </div>


          <div className="flex md:justify-around justify-center flex-wrap xl:mb-[1px] mb-[1px]">
            <div className="max-w-[350px]  xl:max-w-[300px] mb-10 xl:mb-[30px] bg-[#fff] p-6 rounded-xl w-full">
              <p ><img className="mb-5 mt-[17px] mx-auto h-auto  max-w-[60px]" alt="" src={partner_icon1}/></p>
               <h4 className="text-lg text-center text-[#1F55A6] font-semibold">登録費無料/1分で簡単手続き</h4>
            </div>
             <div className="max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full">
               <p ><img className="mb-5  mx-auto h-auto  max-w-[50px]" alt="" src={partner_icon2}/></p>
               <h4 className="text-xm leading-7 text-center text-[#1F55A6] font-semibold">取引以外の収益源</h4>
            </div>
             <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full">
               <p ><img className="mb-5 mx-auto mt-[15px] h-auto  max-w-[70px]" alt="" src={about_icon3}/></p>
               <h4 className="text-xm leading-7  text-center text-[#1F55A6] font-semibold">Just trade独自の分析ツール</h4>
            </div>
             <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full">
               <p ><img className="mb-5 mt-[8px] mx-auto h-auto  max-w-[60px]" alt="" src={partner_icon4}/></p>
               <h4 className="text-xm leading-7 text-center text-[#1F55A6] font-semibold">自己資金は使わないので<br></br>損失をかぶらない</h4>
             </div>
             <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full">
               <p ><img className="mb-5 mx-auto h-auto  max-w-[50px]" alt="" src={about_icon6}/></p>
               <h4 className="text-lg text-center text-[#1F55A6] font-semibold">業界最高水準の報酬割合</h4>
             </div>
             <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full w-full">
               <p ><img className="mb-5 mt-[17px] mx-auto h-auto  max-w-[60px]" alt="" src={partner_icon6}/></p>
               <h4 className="text-lg text-center text-[#1F55A6] font-semibold">安心の日本会社</h4>
            </div>
            <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full">
              <p ><img className="mb-5 mx-auto h-auto  max-w-[60px]" alt="" src={about_icon7}/></p>
              <h4 className="text-lg text-center text-[#1F55A6] font-semibold">取引における<br></br>外付け手数料無料</h4>
            </div>
            <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full">
              <p ><img className="mb-5 mx-auto h-auto  max-w-[70px]" alt="" src={partner_icon8}/></p>
              <h4 className="text-lg text-center text-[#1F55A6] font-semibold">スピーディーな出金対応</h4>
            </div>
            <div className=" max-w-[350px]  xl:max-w-[300px]  mb-10 xl:mb-[30px]  bg-[#fff] p-6 rounded-xl w-full">
              <p ><img className="mb-5 mt-[10px]  mx-auto h-auto  max-w-[100px]" alt="" src={partner_icon9}/></p>
              <h4 className="text-lg text-center text-[#1F55A6] font-semibold">日本語・英語による<br></br>言語サポート</h4>
            </div>
          </div>
    </div>
  </div>



        {/* 固定フッター */}
        {fixedItem}
      </Layout>
  </>
  )
}
export default PropAbout

// モーダル用funciton
export function ModalItem( item ) {

  // (※変更不要)モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])
  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])
  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    const startYOffset = React.useRef(UseScrollPosition())
    startYOffset.current = window.pageYOffset // スクロール位置の左上のY軸座標
    // 調整　モーダル中身（テキスト以外）の設定
    return(
      <div id="modal" className="modal !z-20 text-black w-full text-right" onClick={(event)=>{event.stopPropagation()}}>
        {/* ここでモーダル表示位置を調整 */}
        <div className="absolute left-1/2 w-full mt-[32%] max-w-[320px] -ml-[160px] touch-none" style={{top: startYOffset.current}}> 
          {/* 出力データ */}
          <div className="!text-base absolute w-full bg-white z-20 max-w-[326px]">{item}</div>
          {/* この中のエリア押すと閉じる、閉じるボタン用*/}
          <button onClick={props.onClick} className="w-[30px] h-[30px] bg-black top-0 z-20 relative">
            <div className="!text-base text-white">×</div>
          </button>
        </div>
        {/*この中のエリア押すと閉じる、透過背景用 */}
        <div className="">
          <button onClick={props.onClick} className="w-full bg-gray-700 absolute top-[0px] left-5 h-[8030px] z-10 opacity-50 touch-none"></button>
        </div>
      </div>
    )
  }
  // 親コンポーネント（ボタン）
  return (
    <>
      <div>
        <button onClick={(event)=>{openModal(event)}} className=""> 
          <span className={"" + (isModalOpen? " hidden" :"")}>
            <img alt="" src={hatena_icon}/> {/* 調整　？マークアイコンに差し替える */}
          </span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}} /> :""}
        </button>
      </div>
    </>
  );
}

// (※変更不要)モーダルスクロール位置取得用function
function UseScrollPosition() {
  const isProcessing = useRef(false)
  const [positionY, setPositionY] = useState(0)
  useEffect(() => {
    const handler = () => {
      if (isProcessing.current) return
      isProcessing.current = true
      window.requestAnimationFrame(() => { // Window.requestAnimationFrame()でpositionYステートの更新を間引く
        isProcessing.current = false
        setPositionY(window.scrollY)
      })
    }
    document.addEventListener('scroll', handler, { passive: true }) // スクロールイベントの登録
    return () => { // スクロールイベントの解除
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return positionY // 取得したスクロール量をreturn
}
